<template>
	<div>
		<b-card class="p-1">
			<b-row>
				<b-col cols="12" md="6">
					<table>
						<tbody>
							<tr>
								<td>Name</td>
								<td>: <b>{{ customerDetail.name }}</b></td>
							</tr>
							<tr>
								<td>E-mail</td>
								<td>: {{ customerDetail.email }}</td>
							</tr>
							<!-- <tr>
								<td>KTP</td>
								<td>: </td>
							</tr> -->
							<tr>
								<td>NPWP</td>
								<td>: {{ customerDetail.npwp }}</td>
							</tr>
							<tr>
								<td>EFIN</td>
								<td>: {{ customerDetail.efin }}</td>
							</tr>
						</tbody>
					</table>
				</b-col>
				<b-col cols="12" md="6">
					<table>
						<tbody>
							<tr>
								<td>Phone</td>
								<td>: {{ customerDetail.phone }}</td>
							</tr>
							<tr>
								<td>Address</td>
								<td>: {{ customerDetail.address }}</td>
							</tr>
							<tr>
								<td>Register at</td>
								<td>: {{ customerDetail.registered_at }}</td>
							</tr>
						</tbody>
					</table>
				</b-col>
				<b-col cols="12" class="mt-3">
					<div class="nav nav-tabs">
						<button 
							class="btn px-0 mr-1 mr-sm-3" 
							:class="{ selected: displayQuestion == 'btn1' }"
							@click="switchState('btn1')" 
						>
							Paket Aktif
						</button>
						<button 
							class="btn px-0 mr-1 mr-sm-3" 
							:class="{ selected: displayQuestion == 'btn2' }"
							@click="switchState('btn2')" 
						>
							Paket Service
						</button>
					</div>
					<b-col cols="12" v-if="displayQuestion == 'btn1'">
						<div v-if="customerDetail.active_packages == ''">
							<p>tidak ada data yang ditampilkan</p>
						</div>
						<div 
							class="card border border-primary p-2 rounded"
							v-for="packages, index in customerDetail.active_packages"
							:key="index"
							v-else
						>
							<table>
								<tbody>
									<tr>
										<td class="w-50">Jenis Produk</td>
										<td class="w-50">: <b>{{ packages.name }}</b></td>
									</tr>
									<tr>
										<td class="w-50">Status</td>
										<td class="w-50">: <b-badge variant="success">Active</b-badge></td>
									</tr>
									<tr>
										<td class="w-50">Tanggal Dibeli</td>
										<td class="w-50">: {{ packages.created_at }}</td>
									</tr>
									<tr>
										<td class="w-50">Tanggal Berakhir</td>
										<td class="w-50">: {{ packages.end_date  }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</b-col>
					<b-col cols="12" v-if="displayQuestion == 'btn2'">
						<div v-if="customerDetail.active_services == ''">
							<p>tidak ada data yang ditampilkan</p>
						</div>
						<div 
							class="card border border-primary p-2 rounded"
							v-for="services in customerDetail.active_services"
							:key="services.uuid"
							v-else
						>
							<table>
								<tbody>
									<tr>
										<td class="w-50">Nama Service</td>
										<td class="w-50">: <b>{{ services.name }}</b></td>
									</tr>
									<tr>
										<td class="w-50">Status</td>
										<td class="w-50">:
											<b-badge variant="success" v-if="services.status == 'active'">Active</b-badge>
											<b-badge variant="danger" v-if="services.status == 'inactive'">Inactive</b-badge>
											<b-badge variant="secondary" v-if="services.status == 'coming_soon'">Segera Hadir</b-badge>
											<b-badge variant="warning" v-if="services.status == 'maintenance'">Maintenance</b-badge>
										</td>
									</tr>
									<tr v-if="services.unit == 'minutes'">
										<td class="w-50">Menit yang tersisa</td>
										<td class="w-50">: {{ services.remaining_feature }}</td>
									</tr>
									<tr v-if="services.unit == 'record'">
										<td class="w-50">Fitur yang tersisa</td>
										<td class="w-50">: {{ services.remaining_feature }}</td>
									</tr>
									<tr>
										<td class="w-50">Waktu</td>
										<td class="w-50">
											<!-- <div class="ml-1 d-flex flex-wrap" style="gap: 5px;">
												<b-badge variant="primary">February, 2022</b-badge>
												<b-badge variant="primary">March, 2022</b-badge>
												<b-badge variant="primary">April, 2022</b-badge>
												<b-badge variant="primary">May, 2022</b-badge>
												<b-badge variant="primary">June, 2022</b-badge>
												<b-badge variant="primary">Jule, 2022</b-badge>
												<b-badge variant="primary">August, 2022</b-badge>
												<b-badge variant="primary">September, 2022</b-badge>
											</div> -->
											<div class="ml-1 d-flex wrap" style="gap: 5px;" v-for="time, index in services.times" :key="index">
												<b-badge variant="primary">{{ moment().month(time.month - 1).format('MMMM') }}, {{ time.year }}</b-badge>
											</div>
										</td>
									</tr>
									<tr>
										<td class="w-50">Unit</td>
										<td class="w-50">: {{ services.unit }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</b-col>
				</b-col>
			</b-row>
		</b-card>
		
		<div class="row">
			<div class="col-12 col-md-4">
				<b-card title="Customer Services List Chat">
					<div v-if="listCustomer.length == '0'">
						<p>List Customer is empty</p>
					</div>
					<div class="chat-list" v-else>
						<div 
							class="list-group-item list-group-item-action border-0"
							:class="customersList.room_id == selected ? 'selected-chat' : ''"
							v-for="customersList in listCustomer"
							:key="customersList.uuid"
							@click="chatHistories(customersList.room_id)"
						>
							<div class="d-flex align-items-center">
								<img src="@/assets/images/avatars/hipajak-logo.png" class="rounded-circle mr-1" alt="Hipajak icon" width="40" height="40">
								<div class="flex-grow-1">
									{{ customersList.name }}
								</div>
							</div>
						</div>
					</div>
				</b-card>
				<b-card title="Consultation List Chat">
					<div v-if="listConsultation.length == '0'">
						<p>List Consultation is empty</p>
					</div>
					<div class="chat-list" v-else>
						<div 
							class="list-group-item list-group-item-action border-0"
							:class="consultationList.room_id == selected ? 'selected-chat' : ''"
							v-for="consultationList in listConsultation"
							:key="consultationList.uuid"
							@click="chatHistories(consultationList.room_id)"
						>
							<div class="d-flex align-items-center">
								<img src="@/assets/images/avatars/hipajak-logo.png" class="rounded-circle mr-1" alt="Hipajak icon" width="40" height="40">
								<div class="flex-grow-1">
									{{ consultationList.name }}
								</div>
							</div>
						</div>
					</div>
				</b-card>
			</div>
			<div class="col-12 col-md-8">
				<b-card>
					<div class="border-bottom">
						<h4 class="mb-1">Chat History</h4>
					</div>
					<div class="chat-messages py-2">
						<infinite-loading direction="top" @infinite="infiniteHandler" ref="refInfiniteLoading" v-if="selected != ''" :identifier="selected"></infinite-loading>
						<div
							v-for="customer in chatHistory"
							:key="customer.id"
						>
							<div class="chat-message-right pb-2" v-if="customer.is_customer_own === false">
								<div>
									<img :src="customer.sender.avatar_url" class="rounded-circle mr-1" width="40" height="40">
								</div>
								<div :id="customer.id" class="flex-shrink-1 bg-primary chat-bubles chat-round-right mr-1">
									<div 
										class="border border-dark p-1 mb-1 bg-light rounded"
										style="cursor: pointer;"
										v-if="customer.payload != null && customer.has_file === false"
										@click="clickReply(customer.payload.replied_comment_id)"
									>
										<div class="font-weight-bold mb-1">
											{{ customer.payload.replied_comment_sender_username }}
										</div>
										{{ customer.payload.replied_comment_message }}
									</div>
									<div class="border border-dark mb-1 bg-light rounded" v-if="customer.payload != null && customer.has_file === true">
										<a 
											:href="customer.payload.url" 
											target="_blank"
											v-if="customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'jpg' ||
											customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'png'"
										>
											<img 
												:src="customer.payload.url" 
												alt="" 
												class="image__size"
											>
										</a>
										<a 
											:href="customer.payload.url" 
											target="_blank"
											v-if="customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'pdf' || 
											customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'csv' || 
											customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'docx'"
										>
											{{customer.payload.file_name}}
										</a>
										<video 
											:src="customer.payload.url" 
											controls 
											loop
											v-if="customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'mp4'"
											class="video__size"
										></video>
									</div>
									<div 
										class="d-flex justify-content-between text-white"
										style="gap: 2rem;"
									>
										<div class="font-weight-bold mb-1">
											{{ customer.sender.username }}
										</div>
										<div class="text-muted small text-nowrap text-white">
											{{ customer.timestamp }}
										</div>
									</div>
									<p class="mb-0 text-white">{{ customer.message }}</p>
								</div>
							</div>
							<div class="chat-message-left pb-2" v-else>
								<div>
									<img :src="customer.sender.avatar_url" class="rounded-circle mr-1" width="40" height="40">
								</div>
								<div :id="customer.id" class="flex-shrink-1 bg-light chat-bubles chat-round-left mr-1">
									<div 
										class="border border-dark p-1 mb-1 bg-light rounded"
										style="cursor: pointer;"
										v-if="customer.payload != null && customer.has_file === false"
										@click="clickReply(customer.payload.replied_comment_id)"
									>
										<div class="font-weight-bold mb-1">
											{{ customer.payload.replied_comment_sender_username }}
										</div>
										{{ customer.payload.replied_comment_message }}
									</div>
									<div class="border border-dark p-1 mb-1 bg-light rounded" v-if="customer.payload != null && customer.has_file === true">
										<a 
											:href="customer.payload.url" 
											target="_blank"
											v-if="customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'jpg' ||
											customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'png'"
										>
											<img 
												:src="customer.payload.url" 
												alt="" 
												class="image__size"
											>
										</a>
										<a 
											:href="customer.payload.url" 
											target="_blank"
											v-if="customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'pdf' || 
											customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'csv' || 
											customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'docx'"
										>
											{{customer.payload.file_name}}
										</a>
										<video 
											:src="customer.payload.url" 
											controls 
											loop
											v-if="customer.payload.url.split('.').pop().split(/\#|\?/)[0] == 'mp4'"
											class="video__size"
										></video>
									</div>
									<div 
										class="d-flex justify-content-between"
										style="gap: 2rem;"
									>
										<div class="font-weight-bold mb-1">
											{{ customer.sender.username }}
										</div>
										<div class="text-muted small text-nowrap">
											{{ customer.timestamp }}
										</div>
									</div>
									{{ customer.message }}
								</div>
							</div>
						</div>
					</div>
				</b-card>
			</div>
		</div>
		<b-col cols="12" class="p-0 mt-2">
			<a
				@click="handleBackButton()"
				variant="secondary"
				class="btn waves-effect waves-float waves-light btn-secondary"
			>
				Back
			</a>
		</b-col>
	</div>
</template>

<script>
import 
{ 
	BRow,
	BCol,
    BCard,
    BCardText,
    BTable,
    BTooltip,
    BPagination,
    BFormGroup, 
	BFormRadio,
	BBadge 	
} from 'bootstrap-vue'
import InfiniteLoading from 'vue-infinite-loading'
import flatPickr from 'vue-flatpickr-component'
// import { checkPermission } from '@/auth/utils'
import moment from 'moment'
export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardText,
		BTable,
		BTooltip,
		BPagination,
		BFormGroup, 
		BFormRadio, 
		BBadge,
		flatPickr,
		InfiniteLoading
	},
	data() {
		return {
			moment,
			page: 1,
			displayQuestion: "btn1",
			customerDetail:'',
			listCustomer:[],
			listConsultation:[],
			chatHistory:[],
			selected:'',
		}
	},
	mounted() {
		this.loadCustomer()
	},
	methods: {
		handleBackButton() {
			history.back()
		},
		clickReply(repliedId) {
			document.getElementById(`${repliedId}`).scrollIntoView( {behavior: 'smooth', block: 'nearest', inline: 'start' })
		},
		switchState(data) {
			this.displayQuestion = data;
		},
		infiniteHandler($state) {
			const pages = this.page
			this.$http.get(`admin/chats/contents?room_id=${this.selected}&page=${pages}&limit=20`
			).then( response  => {
				if (response.data.data.length) {	
					this.page += 1;
					this.chatHistory.unshift(...response.data.data);
					$state.loaded();
				} else {
					$state.complete();
				}
			}).catch(error=> {
				this.$swal({
					icon: 'error',
					title: 'Oops...',
					text: 'Tidak ada data yang bisa ditampilkan!',
					footer: error
				})
			})
		},
		chatHistories(roomId){
			if (roomId != this.selected) {
				this.page = 1
				this.chatHistory = []
				this.selected = roomId
			}
		},
		async loadCustomer() {
			const params = this.$route.params.uuid
			await this.$http.get('admin/customers/' + params)
				.then(response => {
					const userData = response.data.data
					this.customerDetail = userData
					console.log(this.customerDetail);
				})
		},
		loadChatCustomerService() {
			const params = this.$route.params.uuid
			this.$http.get('admin/chats/conversation/customer-service?customer_uuid=' + params)
				.then(response => {
					this.listCustomer = response.data.data.data
				})
		},
		loadChatConsultation() {
			const params = this.$route.params.uuid
			this.$http.get('admin/chats/conversation/consultation?customer_uuid=' + params)
				.then(response => {
					this.listConsultation = response.data.data.data
				})
		}
	},
	created() {
		this.loadChatCustomerService()
		this.loadChatConsultation()
	}
}
</script>

<style lang="scss">
	.selected {
		border-bottom: 1px solid #4F00F6;
		border-radius: 0;
	}
	.nav-tabs {
		border-bottom: 1px solid #A1A1A1;
		border-radius: 0;
	}
	.chat-messages {
		display: flex;
		flex-direction: column;
		max-height: 503px;
		overflow-y: scroll
	}
	.chat-message-left,
	.chat-message-right {
		display: flex;
		flex-shrink: 0
	}
	.chat-message-left {
		margin-right: auto
	}
	.chat-message-right {
		flex-direction: row-reverse;
		margin-left: auto
	}
	.flex-grow-0 {
		flex-grow: 0!important;
	}
	.border-top {
		border-top: 1px solid #dee2e6!important;
	}
	.list-group-item {
		margin: 0.75rem 0;
		cursor: pointer;
	}
	.selected-chat {
		color: #fff;
		background: #278ac4;
	}
	.video__size {
		max-width: 250px;
	}
	.image__size {
		width: 100%;
		max-height: 250px;
		object-fit: cover;
	}
	.chat-list {
		max-height: 230px;
		overflow-y: scroll;
	}
	.chat-bubles{
		padding: 18px 16px;
	}
	.chat-round-left {
		border-radius: 0px 12px 12px 12px;
	}
	.chat-round-right {
		border-radius: 12px 12px 0px 12px;
		color: white;
	}
	.shadow-border {
		box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
	}
</style>